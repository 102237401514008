.footer__movie {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  background-image: url("./../../../../assets/image/footer-bg.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  /* padding: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
