@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Nunito", sans-serif !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}
